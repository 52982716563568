.container {
    display: block;
}

.palette {
    display: flex;
}

.swatch {
    text-shadow:
        -1px -1px 2px white,
        1px -1px 2px white,
        -1px 1px 2px white,
        1px 1px 2px white;
    padding: 20px;
    font-weight: bold;
}
